import adambeyer_0 from '../images/adambeyer_0.jpg';
import adambeyer_1 from '../images/adambeyer_1.jpg';
import adambeyer_2 from '../images/adambeyer_2.jpg';
import adambeyer_3 from '../images/adambeyer_3.jpg';

import arminvanbuuren_0 from '../images/arminvanbuuren_0.jpg';
import arminvanbuuren_1 from '../images/arminvanbuuren_1.jpg';
import arminvanbuuren_2 from '../images/arminvanbuuren_2.jpg';
import arminvanbuuren_3 from '../images/arminvanbuuren_3.jpg';


import chrisliebing_0 from '../images/chrisliebing_0.jpg';
import chrisliebing_1 from '../images/chrisliebing_1.jpg';
import chrisliebing_2 from '../images/chrisliebing_2.jpg';
import chrisliebing_3 from '../images/chrisliebing_3.jpg';

import eatseverything_0 from '../images/eatseverything_0.jpg';
import eatseverything_1 from '../images/eatseverything_1.jpg';
import eatseverything_2 from '../images/eatseverything_2.jpg';
import eatseverything_3 from '../images/eatseverything_3.jpg';

import johndigweed_0 from '../images/johndigweed_0.jpg';
import johndigweed_1 from '../images/johndigweed_1.jpg';
import johndigweed_2 from '../images/johndigweed_2.jpg';
import johndigweed_3 from '../images/johndigweed_3.jpg';

import jorisvoorn_0 from '../images/jorisvoorn_0.jpg';
import jorisvoorn_1 from '../images/jorisvoorn_1.jpg';
import jorisvoorn_2 from '../images/jorisvoorn_2.jpg';
import jorisvoorn_3 from '../images/jorisvoorn_3.jpg';


import nicolemoudaber_0 from '../images/nicolemoudaber_0.jpg';
import nicolemoudaber_1 from '../images/nicolemoudaber_1.jpg';
import nicolemoudaber_2 from '../images/nicolemoudaber_2.jpg';

import pauloakenfold_0 from '../images/pauloakenfold_0.jpg';
import pauloakenfold_1 from '../images/pauloakenfold_1.jpg';
import pauloakenfold_2 from '../images/pauloakenfold_2.jpg';
import pauloakenfold_3 from '../images/pauloakenfold_3.jpg';

import sanderdoorn_0 from '../images/sanderdoorn_0.jpg';
import sanderdoorn_1 from '../images/sanderdoorn_1.jpg';
import sanderdoorn_2 from '../images/sanderdoorn_2.jpg';
import sanderdoorn_3 from '../images/sanderdoorn_3.jpg';

import tiesto_0 from '../images/tiesto_0.jpg';
import tiesto_1 from '../images/tiesto_1.jpg';
import tiesto_2 from '../images/tiesto_2.jpg';

import ultra_0 from '../images/ultra_0.jpg';

import spinnin_0 from '../images/spinnin_0.jpg';

import djez_0 from '../images/djez_0.jpg';
import djez_1 from '../images/djez_1.jpg';
import djez_2 from '../images/djez_2.jpg';
import djez_3 from '../images/djez_3.jpg';

import musicfreedom_0 from '../images/musicfreedom_0.jpg';

import jamiejones_0 from '../images/jamiejones_0.jpg';
import jamiejones_1 from '../images/jamiejones_1.jpg';
import jamiejones_2 from '../images/jamiejones_2.jpg';

import absolute_0 from '../images/absolute_0.jpg';
import absolute_1 from '../images/absolute_1.jpg';

import amelie_0 from '../images/amelie_0.jpg';
import amelie_1 from '../images/amelie_1.jpg';
import amelie_2 from '../images/amelie_2.jpg';

import paulette_0 from '../images/paulette_0.jpg';
import paulette_1 from '../images/paulette_1.jpg';
import paulette_2 from '../images/paulette_2.jpg';

import reallies_0 from '../images/reallies_0.jpg';

import seinfeld_0 from '../images/seinfeld_0.jpg';

import gr_0 from '../images/gr_0.jpg';

import pendulum_0 from '../images/pendulum_0.jpg';

import shimza_0 from '../images/shimza_0.jpg';

import mm_0 from '../images/miss-monique_0.jpg';
import mm_1 from '../images/miss-monique_1.jpg';
import mm_2 from '../images/miss-monique_2.jpg';
import mm_3 from '../images/miss-monique_3.jpg';
import mm_4 from '../images/miss-monique_4.jpg';

const Images = {
    absolute_0,
    absolute_1,

    gr_0,
    
    amelie_0,
    amelie_1,
    amelie_2,

    paulette_0,
    paulette_1,
    paulette_2,

    reallies_0,

    adambeyer_0,
    adambeyer_1,
    adambeyer_2,
    adambeyer_3,

    arminvanbuuren_0,
    arminvanbuuren_1,
    arminvanbuuren_2,
    arminvanbuuren_3,

    chrisliebing_0,
    chrisliebing_1,
    chrisliebing_2,
    chrisliebing_3,

    eatseverything_0,
    eatseverything_1,
    eatseverything_2,
    eatseverything_3,

    johndigweed_0,
    johndigweed_1,
    johndigweed_2,
    johndigweed_3,

    jorisvoorn_0,
    jorisvoorn_1,
    jorisvoorn_2,
    jorisvoorn_3,


    nicolemoudaber_0,
    nicolemoudaber_1,
    nicolemoudaber_2,

    pauloakenfold_0,
    pauloakenfold_1,
    pauloakenfold_2,
    pauloakenfold_3,

    sanderdoorn_0,
    sanderdoorn_1,
    sanderdoorn_2,
    sanderdoorn_3,

    sanderdoorn_0,
    sanderdoorn_1,
    sanderdoorn_2,

    tiesto_0,
    tiesto_1,
    tiesto_2,

    ultra_0,

    spinnin_0,

    djez_0,
    djez_1,
    djez_2,
    djez_3,

    musicfreedom_0,

    jamiejones_0,
    jamiejones_1,
    jamiejones_2,

    seinfeld_0,

    pendulum_0,

    shimza_0,

    mm_0,
    mm_1,
    mm_2,
    mm_3,
    mm_4
}


export default Images;